const state = () => ({
  search: '',
  page: 1,
  currentResidenceName: ""
})

const getters = {
  getSearch: state => {
    return state.search
  },
  getPage: state => {
    return state.page
  },
  getCurrentResidenceName: state => {
    return state.currentResidenceName
  },
}

const actions = {
  setSearch({commit}, value) {
    commit('SET_SEARCH', value)
  },
  setPage({commit}, value) {
    commit('SET_PAGE', value)
  },
  set_currentResidenceName({commit}, value){
    commit("SET_CURRENT_RESIDENCE_NAME", value)
  }
}

const mutations = {
  SET_SEARCH(state, value) {
    state.search = value
  },
  SET_PAGE(state, value) {
    state.page = value
  },
  SET_CURRENT_RESIDENCE_NAME(state, value) {
    state.currentResidenceName = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
